import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import { OrderItem } from '../../components/OrderItem/OrderItem';
import InputBox from '../../components/InputBox/InputBox';
import * as yup from "yup";
import { useFormik } from "formik";
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { useNavigate } from 'react-router-dom';
import { FormatDate, apiUrl, referenceGenetor } from '../../helpers/ApiRequest';
import { AddingNotify, ErrorNotify, FrCustomeErrorNorify, InfoNotify, notify } from '../../helpers/Toastify';
import DottedLoading from '../../components/Loading/DottedLoading';
import { Helmet } from 'react-helmet-async';
import { AuthUserProps, OrderProps, UserAdresseProps } from '../../helpers/Props';
import useApiRequest from '@mbs-dev/api-request';

import './CheckoutStyle.css'
import { useOrderRefContext } from '../../contexts/OrderRefContext';


const Checkout: React.FC = () => {

    const user_token = localStorage.getItem('hb_user_token');
    const navigate = useNavigate();
    localStorage.removeItem('isguarded')
    const [isOrderPassed, setIsOrderPassed] = useState(false)
    const [renderFacture, setRenderFacture] = useState(false)
    const [order, setOrder] = useState<OrderProps>();
    // const [orderRef, setOrderRef] = useState<String>();
    const { orderRef, setOrderRef } = useOrderRefContext()

    // const handleSetOrderRef = async (oRef: string) => {
    //     setOrderRef(oRef)
    // }

    useEffect(() => {
        if (!user_token) {
            navigate('/login');
            InfoNotify('Vous devrez être connecté pour effectuer cette action!');
        }
        if (cartQuantity < 1) {
            navigate('/boutique');
            InfoNotify('Votre panier est actuellement vide!');
        }
    }, [user_token, navigate]);

    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
    const storedUser = localStorage.getItem('hb_user');

    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser));
        }
    }, []);

    const { cartItems, cartQuantity, orderData, emptyShoppingCart } = useShoppingCart()
    const [newAdresse, setNewAdresse] = useState(false);
    const [validateAdresse, setValidateAdresse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAdresse, setIsLoadingAdresse] = useState(false);
    const [livraisonAdresse, setLivraisonAdresse] = useState('');
    const [facturationAdresse, setFacturationAdresse] = useState('');
    const [FAdresseInfo, setFAdresseInfo] = useState<UserAdresseProps>();
    const [orderNote, setOrderNote] = useState('');
    const [tryCounter, setTryCounter] = useState<number>(0);
    const MAX_RETRIES = 3
    const { apiRequest } = useApiRequest();
    const paysRegion = 'Maroc';
    let adresseType = "";
    let desabled = false;
    const user = `api/users/${authUser?.id}`;

    !newAdresse ? adresseType = 'livraison' : adresseType = 'newlivraison';
    validateAdresse ? desabled = true : desabled = false

    const handleError = () => {
        setIsLoading(false)
        FrCustomeErrorNorify()
    }

    const validationSchema = yup.object({
        nom: yup.string().required("Veuillez saisir votre nom"),
        prenom: yup.string().required("Veuillez saisir votre prenom"),
        entreprise: yup.string(),
        rueInfo: yup.string().required("Veuillez saisir le numéro et le nom de rue"),
        apartement: yup.string(),
        ville: yup.string().required("Veuillez saisir votre ville"),
        region: yup.string().required("Veuillez saisir votre département"),
        // codepostal: yup.string().required("Veuillez saisir le code postal"),
        telephone: yup.string().matches(/^(05|06|07)[0-9]{8}$/, 'Numéro de téléphone invalide').required('Veuillez saisir votre telephone'),
        email: yup.string().email('Email invalide').required('Veuillez saisir votre adresse e-mail'),
    });

    const formik = useFormik({
        initialValues: {
            nom: "",
            prenom: "",
            entreprise: "",
            pays: paysRegion,
            rueInfo: "",
            apartement: "",
            ville: "",
            region: "",
            // codepostal: "",
            telephone: "",
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const data = { ...values, user, adresseType }

            if (adresseType === 'livraison') {
                notify('Adresse validé avec succès');
                setValidateAdresse(true);
            }

            else if (adresseType === 'newlivraison') {
                const response = await apiRequest({
                    route: `${apiUrl}/user_adresses`,
                    method: 'POST',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });

                if (response.status === 201) {
                    notify('Adresse validé avec succès');
                    setLivraisonAdresse(`api/user_adresses/${response.data.id}`);
                    setValidateAdresse(true);
                }

            }

        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues
    } = formik;

    const handleNewAdresseChange = () => {
        setNewAdresse(!newAdresse);
    };

    const fetchData = async () => {
        try {
            setIsLoadingAdresse(true)
            if (authUser?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/user_adresses/user_id/${authUser?.id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });

                if (response.data?.livraisonAdresse[0] === undefined || response.data?.facturationAdresse[0] === undefined) {
                    navigate('/my-account/edit-address');
                    InfoNotify('Veuillez ajouter votre adresses');
                }

                else {
                    await setValues({
                        nom: response.data.livraisonAdresse[0].nom,
                        prenom: response.data.livraisonAdresse[0].prenom,
                        entreprise: response.data.livraisonAdresse[0].entreprise,
                        pays: paysRegion,
                        rueInfo: response.data.livraisonAdresse[0].rueInfo,
                        apartement: response.data.livraisonAdresse[0].appartement,
                        ville: response.data.livraisonAdresse[0].ville,
                        region: response.data.livraisonAdresse[0].region,
                        // codepostal: response.data.livraisonAdresse[0].codepostal,
                        telephone: response.data.livraisonAdresse[0].telephone,
                        email: response.data.livraisonAdresse[0].email,
                    });

                    setIsLoadingAdresse(false)
                    setFAdresseInfo(response.data.facturationAdresse[0])
                    setLivraisonAdresse(`api/user_adresses/${response.data.livraisonAdresse[0].id}`);
                    setFacturationAdresse(`api/user_adresses/${response.data.facturationAdresse[0].id}`);
                }

            }
        } catch {
            //console.error;
        }
    }

    useEffect(() => {
        fetchData();
    }, [authUser?.id])

    const transformData = async (data: any[]) => {
        const packIdsArray = await Promise.all(
            data.map(async (item) => {
                const { product, pack, ...rest } = item;
                let uniqId = typeof product === 'string' ? product : null;

                if (uniqId !== null) {
                    const response = await apiRequest({
                        route: `${apiUrl}/pack-id/uniq_id/${product}`,
                        method: 'GET'
                    });

                    if (response.status === 200) {
                        return response.data.packId;
                    }
                }

                return null;
            })
        );

        const transformedData = data.map((item: any, index: any) => {
            const { product, ...rest } = item;
            const packId = typeof product === 'string' ? packIdsArray[index] : null;

            return {
                ...(typeof product === 'number' ? { product: `api/products/${product}` } : {}),
                ...(typeof product === 'string' && packId !== null ? { pack: `api/packs/${packId}` } : {}),
                ...rest
            };
        });

        return transformedData;
    };

    const valider = async (currentTryCounter: number = 0) => {
        setIsLoading(true);

        const response = await apiRequest({
            route: `${apiUrl}/last-order`,
            method: 'GET',
            requiresAuth: true,
            token: `${localStorage.getItem('hb_user_token')}`
        });

        if (response.status === 200) {
            const totalPrice = orderData.totalPrice;
            const itemsQuantity = cartItems.length;
            const paymentMethod = orderData.paymentMethod;
            const orderStatus = 'pending';
            const paymentStatus = 'pending';
            const reference = `${referenceGenetor()}${response.data.lastOrderId + 1}`
            // setOrderRef(reference)

            setOrderRef(reference)


            const userEmail = new FormData()
            userEmail.append('email', String(FAdresseInfo?.email))
            userEmail.append('reference', reference)

            let orderItems = await transformData(cartItems);

            const data = {
                facturationAdresse,
                livraisonAdresse,
                totalPrice,
                itemsQuantity,
                orderNote,
                paymentMethod,
                paymentStatus,
                orderItems,
                orderStatus,
                reference,
                dateCreate: FormatDate(new Date()),
                user
            };

            if (paymentMethod === 'livraison') {
                try {
                    const response = await apiRequest({
                        route: `${apiUrl}/orders`,
                        method: 'POST',
                        data: data,
                        requiresAuth: true,
                        token: `${localStorage.getItem('hb_user_token')}`
                    });

                    if (response.status === 201) {

                        setOrder(await response.data)


                        setIsOrderPassed(true)
                        setIsLoading(false)
                        AddingNotify('Commmane');
                        navigate('/my-account/orders')
                        emptyShoppingCart()

                        // apiRequest({
                        //     route: `${apiUrl}/admin-order-email`,
                        //     method: 'POST',
                        //     data: userEmail,
                        //     requiresAuth: true,
                        //     token: `${localStorage.getItem('hb_user_token')}`
                        // });

                    }

                } catch {
                    handleError()

                    // setTryCounter(currentTryCounter + 1);
                    // if (currentTryCounter < MAX_RETRIES) {
                    //     valider(currentTryCounter + 1);
                    // } else {
                    // handleError()
                    // }
                }

            } else if (paymentMethod === 'carte') {
                try {
                    const userEmail = new FormData()
                    userEmail.append('email', String(FAdresseInfo?.email))
                    userEmail.append('reference', reference)

                    const orderInfo = {
                        userEmail: FAdresseInfo?.email,
                        reference: reference
                    };

                    localStorage.setItem('orderInfo', JSON.stringify(orderInfo));

                    //----------- Start CMI Form
                    const cmiForm = new FormData()
                    const billToStreet = `${FAdresseInfo?.rueInfo}, ${FAdresseInfo?.ville}, ${FAdresseInfo?.region}`

                    cmiForm.append('user', String(authUser?.id))
                    cmiForm.append('BillToStreet', billToStreet)
                    cmiForm.append('totalPrice', String(totalPrice))
                    cmiForm.append('reference', String(reference))
                    cmiForm.append('itemsQuantity', String(itemsQuantity))
                    cmiForm.append('email', String(FAdresseInfo?.email))
                    cmiForm.append('telephone', String(FAdresseInfo?.telephone))
                    //----------- End CMI Form

                    try {
                        const response = await apiRequest({
                            route: `${apiUrl}/orders`,
                            method: 'POST',
                            data: data,
                            requiresAuth: true,
                            token: `${localStorage.getItem('hb_user_token')}`
                        });

                        if (response.status === 201) {
                            // apiRequest({
                            //     route: `admin-order-email`,
                            //     method: 'POST',
                            //     body: userEmail
                            // });
                            emptyShoppingCart()

                            // Start CMI Request
                            const cmiResponse = await apiRequest({
                                route: `${apiUrl}/payments/cmi`,
                                method: 'POST',
                                data: cmiForm,
                                requiresAuth: true,
                                token: `${localStorage.getItem('hb_user_token')}`
                            });
                            if (cmiResponse.status === 200) {
                                const { url, data } = cmiResponse.data;
                                const form = document.getElementById(
                                    "cmiPaymentForm"
                                ) as HTMLFormElement;
                                form.method = "POST";
                                form.action = url;
                                Object.keys(data).forEach((name) => {
                                    var input = document.createElement("input");
                                    input.name = name;
                                    input.value = data[name];
                                    form.appendChild(input);
                                });

                                form.submit();

                            } else {
                                handleError()
                            }
                            // End CMI Request
                        }
                    } catch {
                        handleError()

                        // setTryCounter(currentTryCounter + 1);
                        // if (currentTryCounter < MAX_RETRIES) {
                        //     valider(currentTryCounter + 1);
                        // } else {
                        //     handleError()
                        // }
                    }

                } catch {
                    handleError()
                }
            }
        } else {
            handleError()
        }
    }

    return (
        <>
            <Helmet>
                <title>Checkout</title>
            </Helmet>
            <TopHeader />
            <Navbar />

            <div className="shopping-card d-flex justify-content-betdwen align-items-center">
                <div className="container product-cdontainer">
                    <HeaderContainer className='fw-bold fs-1 mt-5' title="Checkout" />

                    <div className="row">
                        <div className="col-md-12 col-lg-7 checkout-card-items mb-4">
                            <div className="adresse-items">
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="edit-form">
                                        <div className="row d-flex justify-content-start align-items-center">

                                            <h4 className='text-start mb-4 mt-2 clr-blue' >Adresse de livraison</h4>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Nom *"
                                                    spanValue="Nom "
                                                    name="nom"
                                                    type="text"
                                                    value={values.nom}
                                                    className={`${touched.nom && errors.nom ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Prenom *"
                                                    spanValue="Prenom "
                                                    name="prenom"
                                                    type="text"
                                                    value={values.prenom}
                                                    className={`${touched.prenom && errors.prenom ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Nom de l'entreprise (facultatif)"
                                                    spanValue="Nom de l'entreprise (facultatif)"
                                                    name="entreprise"
                                                    type="text"
                                                    value={values.entreprise}
                                                    className={`${touched.entreprise && errors.entreprise ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-3 d-none">
                                                <label className={`form-label`}>
                                                    Pays/Region *
                                                </label>
                                                <p>{values.pays}</p>
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Numéro et nom de rue *"
                                                    spanValue="Numéro et nom de rue"
                                                    name="rueInfo"
                                                    type="text"
                                                    value={values.rueInfo}
                                                    className={`mb-3 ${touched.rueInfo && errors.rueInfo ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                                <InputBox
                                                    // label="Bâtiment, appartement, lot, etc. (facultatif)"
                                                    spanValue="Bâtiment, appartement, lot, etc. (facultatif)"
                                                    name="apartement"
                                                    type="text"
                                                    value={values.apartement}
                                                    className={`${touched.apartement && errors.apartement ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Ville *"
                                                    spanValue="Ville"
                                                    name="ville"
                                                    type="text"
                                                    value={values.ville}
                                                    className={`${touched.ville && errors.ville ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Région / Département *"
                                                    spanValue="Région / Département"
                                                    name="region"
                                                    type="text"
                                                    value={values.region}
                                                    className={`${touched.region && errors.region ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            {/* <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Code postal *"
                                                    spanValue="Code postal"
                                                    name="codepostal"
                                                    type="text"
                                                    value={values.codepostal}
                                                    className={`${touched.codepostal && errors.codepostal ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div> */}

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Telephone *"
                                                    spanValue="Telephone"
                                                    name="telephone"
                                                    type="text"
                                                    value={values.telephone}
                                                    className={`${touched.telephone && errors.telephone ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Email *"
                                                    spanValue="Email"
                                                    name="email"
                                                    type="text"
                                                    value={values.email}
                                                    className={`${touched.email && errors.email ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <InputBox
                                                    label="Notes de commande (facultatif)"
                                                    spanValue="Notes de commande (facultatif)"
                                                    name="note"
                                                    textarea={true}
                                                    rows={6}
                                                    value={orderNote}
                                                    // className={touched.message && errors.message ? "is-invalid" : ""}
                                                    // touched={touched}
                                                    // errors={errors}
                                                    handleChange={(e) => setOrderNote(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-start align-items-center">
                                                {
                                                    isLoadingAdresse
                                                        ?
                                                        <DottedLoading />
                                                        :
                                                        <SubmitButton
                                                            className="mt-2 px-3"
                                                            btnLabel={`${newAdresse ? "Valider le nouveau adresse" : "Valider L'adresse"}`}
                                                            disabled={desabled}
                                                        />
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="form-check mt-5 mb-4 ms-1">
                                <input
                                    className="custom-form-check form-check-input shopping-form-check-input"
                                    type="checkbox"
                                    name="payment"
                                    id="newAdresse"
                                    onChange={handleNewAdresseChange}
                                />
                                <label className="form-check-label fs-18 ff-0 fw-bold ms-1" htmlFor="newAdresse">
                                    Expédier à une adresse différente ?
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div className="shopping-card-checkout">

                                <div className="row">
                                    <div className="shopping-card-checkout-soustotal-title ff-0 fw-bold fs-5 mb-1">Articles de commande :</div>
                                    <hr className='hr-tag' />
                                    <div className="col-md-12 col-lg-12 checkout-items">
                                        <div className="checkout-items-centent">

                                            {cartItems.map(item => (
                                                <OrderItem key={item.product} {...item} />
                                            ))}

                                        </div>

                                    </div>
                                </div>

                                <div className="row">

                                    <hr className='hr-tag' />

                                    <div className="row">
                                        <div className="shopping-card-checkout-total d-flex justify-content-between px-5 mb-5">
                                            <div className="shopping-card-checkout-total-title px-">TOTAL</div>
                                            <div className="shopping-card-checkout-total-value">{orderData.totalPrice.toFixed(2)} DH</div>

                                        </div>
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-12 text-start">
                                                {
                                                    isLoading ?
                                                        <DottedLoading />
                                                        :
                                                        <SubmitButton
                                                            className="mt-1 px-5"
                                                            btnLabel="Valider la commande"
                                                            onClick={() => valider()}
                                                            disabled={!desabled}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='d-none'>
                <form name='cmiPaymentForm' id="cmiPaymentForm"></form>
            </div>
        </>
    )
}

export { Checkout };